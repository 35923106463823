import { milestoneCopy } from "v2/constants/milestones";
import { BulkEventPBType, MilestoneUpdatePBType } from "v2/constants/events";

// I copied this file and modiefied it to just return events

// The milestoneCopy object has the milestones 'acknowledged', 'first_action', and 'started' all mapping to 'Investigating'
// The functions meetsTimelineDeleteByMilestoneUpdateCriteria and meetsTimelineDeleteByBulkUpdateCriteria functions below evaluates whether timeline event contains milestones where the milestone copy for the original and current milestones are the same, except when the currentMilestone is 'started'
// Milestone updates are made through MilestoneUpdatePBType and BulkEventPBType, so there are two functions that filter the timeline for milestone updates with redundant milestone copy
// (cleanUpTimelineByMilestoneUpdatePBType and cleanUpTimelineByBulkUpdatePBType)
// i.e., This is to avoid milestone updates in the UI that say "Transitioned from Investigating to Investigating"

// milestone updates
export const meetsTimelineDeleteByMilestoneUpdateCriteria = (item) =>
  milestoneCopy[item.details.currentMilestone] ===
    milestoneCopy[item.details.originalMilestone] &&
  item.details.currentMilestone !== "started";

export const cleanUpTimelineByMilestoneUpdatePBType = (timeline) => {
  if (!timeline) return [];
  let result = timeline?.filter(
    (timelineEvent) =>
      !(
        timelineEvent.details["@type"] === MilestoneUpdatePBType &&
        meetsTimelineDeleteByMilestoneUpdateCriteria(timelineEvent)
      )
  );
  return result;
};

// bulk updates
const meetsTimelineDeleteByBulkUpdateCriteria = (item) => {
  return (
    milestoneCopy[item.details.milestoneUpdate.currentMilestone] ===
      milestoneCopy[item.details.milestoneUpdate.originalMilestone] &&
    !(item.details.milestoneUpdate.currentMilestone === "started")
  );
};

export const cleanUpTimelineByBulkUpdatePBType = (originalTimeline) => {
  if (!originalTimeline) return [];
  const timeline = JSON.parse(JSON.stringify(originalTimeline));
  for (const timelineEvent in timeline) {
    if (timeline[timelineEvent].details["@type"] === BulkEventPBType) {
      const keysCount = Object.keys(timeline[timelineEvent].details);
      const keysCountExcludingType = keysCount?.filter(
        (key) => key !== "@type"
      );
      if (keysCountExcludingType.includes("milestoneUpdate")) {
        if (meetsTimelineDeleteByBulkUpdateCriteria(timeline[timelineEvent])) {
          if (keysCountExcludingType.length === 1) {
            delete timeline[timelineEvent];
          } else if (keysCountExcludingType.length > 1) {
            delete timeline[timelineEvent].details.milestoneUpdate;
          }
        }
      }
    }
  }
  return timeline?.filter((event) => !!event);
};

export const cleanUpTimelineAllMilestoneUpdates = (timeline = []) => {
  return cleanUpTimelineByBulkUpdatePBType(
    cleanUpTimelineByMilestoneUpdatePBType(timeline)
  );
};

export const LastEventFilter = (timeline) => {
  if (!timeline) return [];
  const minimalTimeline = cleanUpTimelineAllMilestoneUpdates(timeline);
  const length = minimalTimeline.length;
  let result = [];
  if (length > 0) {
    result.push(minimalTimeline[0]);
  }
  return result;
};

/**
 * Gets the phase type for a milestone
 * @param {string} milestoneSlug - The milestone slug
 * @param {Array} lifecyclePhases - The incident's lifecycle phases data
 * @returns {string} - The phase type (STARTED, ACTIVE, POST_INCIDENT, CLOSED, or DEFAULT)
 */
export const getMilestonePhase = (milestoneSlug, lifecyclePhases = []) => {
  if (!milestoneSlug || !lifecyclePhases || lifecyclePhases.length === 0) {
    return "DEFAULT";
  }

  if (isInPostIncidentOrClosed(milestoneSlug, lifecyclePhases)) {
    return "POST_INCIDENT";
  }
  
  for (const phase of lifecyclePhases) {
    const foundMilestone = phase.milestones.find(m => m.slug === milestoneSlug);
    if (foundMilestone) {
      return phase.type;
    }
  }
  
  return "DEFAULT";
};

/**
 * Determines if a milestone is in the POST_INCIDENT or CLOSED phases
 * @param {string} milestoneSlug - The milestone slug to check
 * @param {Array} lifecyclePhases - The incident's lifecycle phases data
 * @returns {boolean} - True if milestone is in POST_INCIDENT or CLOSED phases
 */
export const isInPostIncidentOrClosed = (milestoneSlug, lifecyclePhases = []) => {
  // Handle empty array case
  if (!lifecyclePhases || lifecyclePhases.length === 0) {
    return false;
  }
  
  // Find the POST_INCIDENT and CLOSED phases
  const postIncidentPhase = lifecyclePhases.find(p => p.type === "POST_INCIDENT");
  const closedPhase = lifecyclePhases.find(p => p.type === "CLOSED");
  
  // Check if the milestone exists in either phase
  const inPostIncident = postIncidentPhase?.milestones?.some(m => m.slug === milestoneSlug) || false;
  const inClosed = closedPhase?.milestones?.some(m => m.slug === milestoneSlug) || false;
  
  return inPostIncident || inClosed;
};

/**
 * Gets the display name for a milestone
 * @param {string} milestoneSlug - The milestone slug
 * @param {Array} lifecyclePhases - The incident's lifecycle phases data
 * @returns {string} - The milestone's display name
 */
export const getMilestoneName = (milestoneSlug, lifecyclePhases = []) => {
  // Search all phases for the milestone to get its name
  for (const phase of lifecyclePhases) {
    const foundMilestone = phase.milestones.find(m => m.slug === milestoneSlug);
    if (foundMilestone) {
      return foundMilestone.name;
    }
  }
  
  // If not found, return the slug (potentially with transformations)
  return milestoneSlug.startsWith("postmortem_") 
    ? milestoneSlug.replace("postmortem_", "retrospective ") 
    : milestoneSlug;
};

/**
 * Gets the latest milestone from the timeline
 * @param {Array} timeline - The incident timeline
 * @param {Array} lifecyclePhases - The incident's lifecycle phases data
 * @returns {string} - The milestone to display
 */
export const getLatestMilestone = (timeline = [], lifecyclePhases = []) => {
  // Sort timeline by time in descending order to get the truly latest entry
  const sortedTimeline = [...timeline].sort((a, b) => 
    new Date(b.time) - new Date(a.time)
  );
  
  const latestMilestone = sortedTimeline.find((update) => {
    return update?.details?.milestoneUpdate?.currentMilestone;
  });
  
  const milestoneSlug = latestMilestone?.details?.milestoneUpdate?.currentMilestone;
  
  if (!milestoneSlug) return null;
  
  // Check if milestone is in POST_INCIDENT or CLOSED phases
  if (isInPostIncidentOrClosed(milestoneSlug, lifecyclePhases)) {
    return "Resolved";
  }
  
  // Otherwise, return the milestone's name
  return getMilestoneName(milestoneSlug, lifecyclePhases);
};

/**
 * Gets the latest milestone from timestamps
 * @param {Object} milestones - The incident milestones timestamps
 * @param {Array} lifecyclePhases - The incident's lifecycle phases data
 * @returns {string} - The milestone to display
 */
export const getLatestMilestoneFromTimestamps = (milestones = {}, lifecyclePhases = []) => {
  let latestMilestoneSlug = null;
  
  // Special case: if resolved exists, use it (maintaining original behavior)
  if (milestones.resolved) {
    latestMilestoneSlug = "resolved";
  } else {
    // Find the latest milestone by timestamp
    for (const milestone in milestones) {
      if (
        !latestMilestoneSlug ||
        milestones[milestone] > milestones[latestMilestoneSlug]
      ) {
        latestMilestoneSlug = milestone;
      }
    }
  }
  
  if (!latestMilestoneSlug) return null;
  
  // Check if milestone is in POST_INCIDENT or CLOSED phases
  if (isInPostIncidentOrClosed(latestMilestoneSlug, lifecyclePhases)) {
    return {
      name: "Resolved",
      phase: "POST_INCIDENT",
      slug: "resolved",
    };
  }
  
  // Otherwise, return the milestone's name
  return {
    name: getMilestoneName(latestMilestoneSlug, lifecyclePhases),
    phase: getMilestonePhase(latestMilestoneSlug, lifecyclePhases),
    slug: latestMilestoneSlug,
  };
};

export const getFilteredTimelineEvents = (timeline) => {
  return cleanUpTimelineAllMilestoneUpdates(timeline);
};

export const getNoteFromEvent = (event) => {
  return event?.details?.note?.note || event?.details?.note;
};

export const getLatestNoteEvent = (timeline = []) => {
  const noteEvent = timeline?.find((update) => {
    return update?.details?.note;
  });
  return noteEvent;
};

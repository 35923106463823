import React from "react";
import IncidentHeader from "v2/components/common/IncidentHeader";
import IncidentRow from "../components/common/IncidentRow";
import TimelineEventTypes from "./TimelineEventTypes";
import { getIncidentCondition } from "v2/helpers/conditions";
import { milestoneCopy } from "v2/constants/milestones";
import {
  getFilteredTimelineEvents,
  getLatestMilestoneFromTimestamps,
  getMilestoneName,
  getMilestonePhase,
} from "v2/helpers/timeline";
import { usePayloadContext } from "components/usePayloadContext";

const IncidentDetailTimeline = ({ incident }) => {
  const { conditions } = usePayloadContext();
  const condition = getIncidentCondition(incident, conditions);
  const events = getFilteredTimelineEvents(incident?.timeline);
  const headerMilestone = getLatestMilestoneFromTimestamps(
    incident?.timestamps,
    incident?.lifecyclePhases
  );

  console.log(headerMilestone);

  
  return (
    <>
      <div className="mb-10">
        <IncidentHeader
          bannerType={condition}
          milestone={headerMilestone}
          title={incident?.title}
          incident={incident}
        />
      </div>

      <div className="flex flex-col gap-6 mb-12 border rounded border-fh-gray-6 bg-white dark:bg-fh-gray-dark-3 dark:border-fh-gray-dark-6 p-6">
        <h3 className="text-xl">Latest Updates</h3>
        {events?.length === 0 && (
          <div className="text-sm text-fh-gray-light-11 dark:text-fh-gray-dark-11">
            No updates have been added to this incident.
          </div>
        )}
        {events?.length > 0 &&
          events?.map((event, index) => {
            const eventType = event?.details?.["@type"];
            const Event = TimelineEventTypes?.[eventType];
            let currentMilestone = null;
            if (event?.details?.milestoneUpdate?.currentMilestone) {
              currentMilestone =
                // milestoneCopy[event.details.milestoneUpdate.currentMilestone];
                getMilestoneName(event.details.milestoneUpdate.currentMilestone, incident?.lifecyclePhases);
            }
            return (
              <IncidentRow
                key={`${index}-${event?.time}`}
                milestone={currentMilestone}
                timestamp={event.time}
                phase={getMilestonePhase(event?.details?.milestoneUpdate?.currentMilestone, incident?.lifecyclePhases)}
              >
                {Event && <Event details={event?.details} />}
              </IncidentRow>
            );
          })}
      </div>
    </>
  );
};

export default IncidentDetailTimeline;
